<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row
      ref="loginform"
      justify="center"
    >
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                {{ $t('login.login') }}
              </h1>
            </div>
          </template>

          <v-card-text class="text-center">
            <v-text-field
              v-model="username"
              :rules="usernameRules"
              color="secondary"
              :label="$t('login.username')"
              prepend-icon="mdi-email"
            />

            <v-text-field
              v-model="password"
              :rules="passwordRules"
              type="password"
              class="mb-8"
              color="secondary"
              :label="$t('login.password')"
              prepend-icon="mdi-lock-outline"
            />

            <pages-btn
              :disabled="username.length === 0 || password.length === 0"
              large
              color=""
              depressed
              class="v-btn--text success--text"
              @click="login"
            >
              {{ $t('login.login') }}
            </pages-btn>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'PagesLogin',
    components: {
      PagesBtn: () => import('./components/Btn'),
    },
    data () {
      return {
        username: '',
        password: '',
        usernameRules: [
          v => !!v || this.$t('error.username'),
        ],
        passwordRules: [v => !!v || this.$t('error.password')],
        loading: false,
      }
    },
    methods: {
      login (e) {
        e.preventDefault()

        this.loading = true

        this.$store
          .dispatch('user/login', {
            username: this.username,
            password: this.password,
          })
          .then(hasSucceeded => {
            if (hasSucceeded) {
              localStorage.setItem('authorized', 'true')
              this.$router.push('/')
            } else {
              this.$store.commit('viewstate/notify', {
                color: 'error',
                text:
                  this.$t('error.username-password'),
              })
            }
          })
          .catch(hasFailed => {
            this.$store.commit('viewstate/notify', {
              color: 'error',
              text:
                this.$t('error.username-password'),
            })
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>
